import React, { useState } from 'react';

function MarketingAcordion() {
  const [activeAccordion, setActiveAccordion] = useState(1);

  const toggleAccordion = (accordionNumber) => {
    setActiveAccordion(accordionNumber === activeAccordion ? null : accordionNumber);
  };

  const renderAccordionItem = (number, title, content) => (
    <div key={number} className="single-accordian-wrape">
      <div className="single-accordian-header">
        <button
          className={`accordion-button ${activeAccordion === number ? '' : 'collapsed'}`}
          type="button"
          onClick={() => toggleAccordion(number)}
        >
          {title}
        </button>
      </div>
      <div
        className={`accordion-collapse collapse ${activeAccordion === number ? 'show' : ''}`}
      >
        <div className="accordion-body">
          <p>{content}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="khalif-accordian-wrape2 bgc-2 pt-100 pb-100 avatar">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 offset-xl-1">
            <div className="accordian-left-content">
              <img src="assets/img/accordion/1.png" alt="img" />
              <div className="accordian-style-img" data-parallax='{"x": 40}'>
                <img src="assets/img/accordion/2.png" alt="img" />
              </div>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="about2-short-brief">
              <h2 className="text-uppercase" data-parallax='{"x": 70}'>
                <span>some</span> q&a section here
              </h2>
              <div className="khalif-accordian-wrapeer mt-40">
                <div className="accordion" id="accordionExample">
                  {renderAccordionItem(1, 'Accordion Item #1', 'What was the marketing strategy employed in the case study, and how did it align with the company overall business goals? ')}
                  {renderAccordionItem(2, 'Accordion Item #2', 'What were the key success factors and challenges faced during the marketing campaign or initiative? ')}
                  {renderAccordionItem(3, 'Accordion Item #3', 'How did the company measure the effectiveness of its marketing efforts, and what were the results?')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketingAcordion;

