import React from "react";
import { Link } from "react-router-dom";

function DevelopmentHero() {
  return (
    <div
      id="khalif-breadcrumb-wrap"
      className="khalif-breadcrumb-wrap breadcrumb-bg text-center d-flex align-items-center avatar"
      style={{ backgroundImage: `url('/assets/img/Development1.jpg')` }}
    >
      <div className="container">
        <div className="row">
          <div className="offset-xl-2 col-xl-8">
            <div className="khalif-breadcrumb-content">
              <h2>Development</h2>
              <ul className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/" title="Home">
                    Home /
                  </Link>
                </li>
                <li className="breadcrumb-item active">Case Studies</li>
              </ul>
              <p>
                In a recent e-commerce platform overhaul, we boosted performance
                and user experience through modern technology and agile
                development.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevelopmentHero;
