import React, { useEffect } from "react";
import DevelopmentHero from "./developmentHero";
import DevelopmentServices from "./developmentServices";
// import DevelopmentAccordion from "./developmentAccordian";
// import BrandsComponent from "../../../components/brands";
function DevelopmentIndex() {
  useEffect(()=>{
    document.title = "AFFINITY Development"
  },[])
  return (
    <>
    <DevelopmentHero />
    <DevelopmentServices />
    {/* <DevelopmentAccordion /> */}
    {/* <BrandsComponent /> */}
    </>
  );
}

export default DevelopmentIndex;
