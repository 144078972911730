import React from 'react';
import { Link } from 'react-router-dom';

function VideoSection() {
  return (
    <div className="khalif-video-wrape text-center video-bg avatar">
      <div className="videos-icon-text">
        <h3 data-aos="fade-down" data-aos-duration="500">watch this video</h3>
        <Link className="popup-video wow pulse infinite" to="https://www.youtube.com/watch?v=snvzakfcTmY">
          <i className="fa fa-play"></i>
        </Link>
      </div>
    </div>
  );
}

export default VideoSection;
