import React from "react";
import { Link } from "react-router-dom";

function ServicesSection() {
  return (
    <>
      <div className="khalif-services-wrape services-bg pt-100 pb-100 avatar">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title-wrape text-center text-uppercase position-relative mb-100">
                <h3>services</h3>
                <h4>our services</h4>
              </div>
            </div>
            <Link to="/servicesIndex" className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div
                className="landing-page-card single-services-wrape bg-white"
                data-aos="fade-right"
                data-aos-delay="500"
              >
                <div className="services-style-shape" data-parallax='{"y": 20}'>
                  <svg
                    height="512"
                    viewBox="0 0 32 32"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="layer1">
                      <path
                        id="path1855"
                        d="m6.0001 9.99995a1.9999999 1.9999999 0 0 1 -2.0001 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2.0001 1.99997z"
                      />
                      <path
                        id="path1857"
                        d="m18.0001 9.99995a1.9999999 1.9999999 0 0 1 -2 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2 1.99997z"
                      />
                      <path
                        id="path2159"
                        d="m30.0001 9.99995a1.9999999 1.9999999 0 0 1 -2 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2 1.99997z"
                      />
                      <path
                        id="path2161"
                        d="m30.0001 21.99997a1.9999999 1.9999999 0 0 1 -2 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2 1.99997z"
                      />
                      <path
                        id="path1213"
                        d="m17.6465 21.99997a1.9999999 1.9999999 0 0 1 -2 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2 1.99997z"
                      />
                      <path
                        id="path1215"
                        d="m6.0001 21.99997a1.9999999 1.9999999 0 0 1 -2.0001 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2.0001 1.99997z"
                      />
                    </g>
                  </svg>
                </div>
                <div className="single-services-icon">
                  <span>
                    <svg
                      height="512pt"
                      viewBox="0 0 512 512"
                      width="512pt"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m442.53125 79.523438h59.46875c5.523438 0 10-4.476563 10-10v-59.523438c0-5.523438-4.476562-10-10-10h-59.46875c-5.523438 0-10 4.476562-10 10v19.761719h-136.796875v-19.761719c0-5.523438-4.476563-10-10-10h-59.46875c-5.523437 0-10 4.476562-10 10v19.761719h-136.796875v-19.761719c0-5.523438-4.476562-10-10-10h-59.46875c-5.523438 0-10 4.476562-10 10v59.523438c0 5.523437 4.476562 10 10 10h59.46875c5.523438 0 10-4.476563 10-10v-19.761719h83.1875c-29.292969 13.28125-55.703125 32.789062-77.222656 57.457031-33.742188 38.679688-53.25 87.625-55.503906 138.78125h-19.929688c-5.523438 0-10 4.476562-10 10v59.523438c0 5.523437 4.476562 10 10 10h59.46875c5.523438 0 10-4.476563 10-10v-59.523438c0-5.523438-4.476562-10-10-10h-19.515625c4.609375-94.238281 73.933594-174.484375 166.3125-192.523438v16.050782c0 5.523437 4.476563 10 10 10h59.46875c5.523437 0 10-4.476563 10-10v-16.050782c92.378906 18.039063 161.703125 98.285157 166.3125 192.523438h-19.515625c-5.523438 0-10 4.476562-10 10v59.523438c0 5.523437 4.476562 10 10 10h59.46875c5.523438 0 10-4.476563 10-10v-59.523438c0-5.523438-4.476562-10-10-10h-19.929688c-2.253906-51.15625-21.761718-100.101562-55.503906-138.78125-21.519531-24.667969-47.929687-44.175781-77.222656-57.457031h83.1875v19.765625c0 5.519531 4.476562 9.996094 10 9.996094zm-383.0625-20h-39.46875v-39.523438h39.46875zm0 246h-39.46875v-39.523438h39.46875zm432.53125 0h-39.46875v-39.523438h39.46875zm-216.265625-246h-39.46875v-39.523438h39.46875zm176.796875-39.523438h39.46875v39.523438h-39.46875zm0 0" />
                      <path d="m264.757812 110.175781c-1.757812-3.191406-5.113281-5.175781-8.757812-5.175781s-7 1.984375-8.757812 5.175781l-41.535157 75.40625c-2.664062 4.839844-.902343 10.921875 3.9375 13.582031 4.832031 2.664063 10.917969.90625 13.582031-3.933593l22.773438-41.347657v73.746094c-13.882812 4.28125-24 17.230469-24 32.5 0 18.746094 15.253906 34 34 34s34-15.253906 34-34c0-15.269531-10.117188-28.21875-24-32.5v-73.746094l68.605469 124.5625c-22.539063 22.796876-37.902344 52.039063-43.871094 83.554688h-69.46875c-5.972656-31.515625-21.335937-60.757812-43.871094-83.554688l11.460938-20.808593c2.664062-4.835938.902343-10.917969-3.933594-13.582031-4.835937-2.664063-10.921875-.90625-13.585937 3.933593l-15.15625 27.523438c-2.226563 4.039062-1.398438 9.082031 2.003906 12.199219 21.535156 19.722656 36.488281 45.847656 42.675781 74.289062h-9.3125c-16.542969 0-30 13.460938-30 30 0 11.671875 6.699219 21.796875 16.453125 26.753906v83.246094c0 5.523438 4.476562 10 10 10s10-4.476562 10-10v-80h116v80c0 5.523438 4.476562 10 10 10s10-4.476562 10-10v-83.246094c9.753906-4.957031 16.453125-15.085937 16.453125-26.753906 0-16.542969-13.457031-30-30-30h-9.3125c6.1875-28.445312 21.140625-54.566406 42.675781-74.292969 3.402344-3.117187 4.230469-8.15625 2.003906-12.199219zm-8.757812 163.953125c-7.71875 0-14-6.28125-14-14 0-7.722656 6.28125-14 14-14s14 6.277344 14 14c0 7.71875-6.28125 14-14 14zm74.453125 117.871094c0 5.515625-4.484375 10-10 10h-128.90625c-5.515625 0-10-4.484375-10-10s4.484375-10 10-10h128.90625c5.515625 0 10 4.484375 10 10zm0 0" />
                      <path d="m196.628906 232.121094c2.632813 0 5.210938-1.0625 7.070313-2.921875 1.859375-1.871094 2.929687-4.4375 2.929687-7.078125 0-2.632813-1.070312-5.199219-2.929687-7.070313-1.859375-1.859375-4.4375-2.929687-7.070313-2.929687-2.640625 0-5.21875 1.070312-7.078125 2.929687-1.859375 1.871094-2.921875 4.4375-2.921875 7.070313 0 2.640625 1.0625 5.207031 2.921875 7.078125 1.859375 1.859375 4.4375 2.921875 7.078125 2.921875zm0 0" />
                    </svg>
                  </span>
                </div>
                <h3>
                  <Link to="/servicesIndex" className="title-text-purple">AI-Integration</Link>
                </h3>
                <p className="text-black">
                Boost customer engagement with Al chatbots for appointment scheduling and instant support. Strengthen audience connections. </p>
              </div>
            </Link>
            <Link to="/serviceMarketingIndex" className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div
                className="landing-page-card single-services-wrape bg-white"
                data-aos="fade-right"
                data-aos-delay="300"
              >
                <div className="services-style-shape" data-parallax='{"y": 30}'>
                  <svg
                    id="svg6331"
                    height="512"
                    viewBox="0 0 32 32"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="layer2">
                      <path
                        id="path11111"
                        d="m6.0001 9.99995a1.9999999 1.9999999 0 0 1 -2.0001 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2.0001 1.99997z"
                      />
                      <path
                        id="path1957"
                        d="m18.0001 9.99995a1.9999999 1.9999999 0 0 1 -2 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2 1.99997z"
                      />
                      <path
                        id="path2259"
                        d="m30.0001 9.99995a1.9999999 1.9999999 0 0 1 -2 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2 1.99997z"
                      />
                      <path
                        id="path1221"
                        d="m30.0001 21.99997a1.9999999 1.9999999 0 0 1 -2 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2 1.99997z"
                      />
                      <path
                        id="path1243"
                        d="m17.6465 21.99997a1.9999999 1.9999999 0 0 1 -2 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2 1.99997z"
                      />
                      <path
                        id="path1225"
                        d="m6.0001 21.99997a1.9999999 1.9999999 0 0 1 -2.0001 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2.0001 1.99997z"
                      />
                    </g>
                  </svg>
                </div>
                <div className="single-services-icon">
                  <span>
                    <svg
                      height="682pt"
                      viewBox="-21 -55 682.66669 682"
                      width="682pt"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m598.316406-2h-556.632812c-22.980469 0-41.683594 18.703125-41.683594 41.683594v487.648437c0 22.984375 18.703125 41.683594 41.683594 41.683594h556.632812c22.980469 0 41.683594-18.699219 41.683594-41.683594v-135.816406c0-6.902344-5.59375-12.5-12.5-12.5s-12.5 5.597656-12.5 12.5v135.816406c0 9.199219-7.484375 16.683594-16.683594 16.683594h-556.632812c-9.199219 0-16.683594-7.484375-16.683594-16.683594v-394.625h590v116.308594c0 6.90625 5.59375 12.5 12.5 12.5s12.5-5.59375 12.5-12.5v-209.332031c0-22.980469-18.703125-41.683594-41.683594-41.683594zm-573.316406 109.707031v-68.023437c0-9.199219 7.484375-16.683594 16.683594-16.683594h556.632812c9.199219 0 16.683594 7.484375 16.683594 16.683594v68.023437zm0 0" />
                      <path d="m102.910156 77.855469h-10.832031c-6.902344 0-12.5-5.597657-12.5-12.5 0-6.90625 5.597656-12.5 12.5-12.5h10.832031c6.902344 0 12.5 5.59375 12.5 12.5 0 6.902343-5.597656 12.5-12.5 12.5zm0 0" />
                      <path d="m164.578125 77.855469h-10.832031c-6.902344 0-12.5-5.597657-12.5-12.5 0-6.90625 5.597656-12.5 12.5-12.5h10.832031c6.90625 0 12.5 5.59375 12.5 12.5 0 6.902343-5.59375 12.5-12.5 12.5zm0 0" />
                      <path d="m226.246094 77.855469h-10.835938c-6.898437 0-12.5-5.597657-12.5-12.5 0-6.90625 5.601563-12.5 12.5-12.5h10.835938c6.902344 0 12.5 5.59375 12.5 12.5 0 6.902343-5.597656 12.5-12.5 12.5zm0 0" />
                      <path d="m497.09375 77.855469h-205.839844c-6.902344 0-12.5-5.597657-12.5-12.5 0-6.90625 5.597656-12.5 12.5-12.5h205.839844c6.90625 0 12.5 5.59375 12.5 12.5 0 6.902343-5.59375 12.5-12.5 12.5zm0 0" />
                      <path d="m547.925781 77.859375c-.824219 0-1.636719-.089844-2.453125-.253906-.800781-.160157-1.585937-.394531-2.332031-.707031-.753906-.3125-1.480469-.703126-2.164063-1.152344-.679687-.449219-1.3125-.976563-1.898437-1.550782-.578125-.574218-1.089844-1.222656-1.554687-1.902343-.449219-.683594-.835938-1.410157-1.148438-2.160157s-.550781-1.535156-.710938-2.335937c-.164062-.8125-.238281-1.628906-.238281-2.4375 0-.824219.074219-1.640625.238281-2.453125.160157-.800781.398438-1.574219.710938-2.335938.3125-.75.699219-1.476562 1.148438-2.148437.464843-.691406.976562-1.324219 1.554687-1.914063.585937-.574218 1.21875-1.085937 1.898437-1.550781.683594-.449219 1.410157-.832031 2.164063-1.144531.746094-.3125 1.53125-.554688 2.332031-.714844 1.617188-.328125 3.277344-.328125 4.886719 0 .804687.160156 1.589844.402344 2.339844.714844.753906.3125 1.488281.695312 2.164062 1.144531.6875.464844 1.324219.976563 1.898438 1.550781.578125.589844 1.097656 1.222657 1.550781 1.914063.453125.671875.835938 1.398437 1.148438 2.148437.3125.761719.554687 1.535157.714843 2.335938.160157.8125.25 1.628906.25 2.453125 0 .808594-.089843 1.625-.25 2.4375-.160156.800781-.402343 1.585937-.714843 2.335937s-.695313 1.476563-1.148438 2.160157c-.453125.679687-.972656 1.328125-1.550781 1.902343-.574219.574219-1.222657 1.101563-1.898438 1.550782-.6875.449218-1.410156.839844-2.164062 1.152344-.75.3125-1.535157.546874-2.339844.707031-.808594.164062-1.625.253906-2.433594.253906zm0 0" />
                      <path d="m627.5 334.605469c-.8125 0-1.636719-.085938-2.4375-.246094-.800781-.164063-1.585938-.402344-2.335938-.714844-.765624-.3125-1.492187-.699219-2.164062-1.148437-.6875-.449219-1.324219-.976563-1.898438-1.550782-.578124-.570312-1.101562-1.210937-1.550781-1.898437-.453125-.675781-.839843-1.402344-1.152343-2.164063-.3125-.746093-.5625-1.539062-.710938-2.335937-.164062-.800781-.25-1.625-.25-2.441406 0-.808594.085938-1.632813.25-2.433594.148438-.800781.398438-1.589844.710938-2.335937.3125-.761719.699218-1.488282 1.152343-2.164063.449219-.6875.972657-1.328125 1.550781-1.898437.574219-.574219 1.210938-1.101563 1.898438-1.5625.671875-.4375 1.398438-.824219 2.152344-1.136719.761718-.3125 1.546875-.5625 2.347656-.714844 1.613281-.324219 3.277344-.324219 4.875 0 .800781.152344 1.585938.402344 2.335938.714844.765624.3125 1.492187.699219 2.164062 1.136719.6875.460937 1.324219.988281 1.898438 1.5625 2.328124 2.324218 3.664062 5.546874 3.664062 8.832031 0 .816406-.074219 1.640625-.238281 2.441406-.160157.800781-.410157 1.589844-.722657 2.335937-.3125.761719-.699218 1.488282-1.152343 2.164063-.449219.6875-.972657 1.320313-1.550781 1.898437-.574219.574219-1.210938 1.101563-1.898438 1.550782-.671875.449218-1.398438.835937-2.164062 1.148437-.75.3125-1.535157.550781-2.335938.714844-.800781.160156-1.625.246094-2.4375.246094zm0 0" />
                      <path d="m196.789062 488.03125c-10.769531 0-20.890624-4.195312-28.5-11.808594l-93.273437-93.269531c-7.613281-7.613281-11.808594-17.734375-11.808594-28.503906 0-10.761719 4.195313-20.882813 11.808594-28.496094l93.273437-93.273437c7.613282-7.613282 17.730469-11.804688 28.5-11.804688 10.765626 0 20.886719 4.191406 28.5 11.808594 7.609376 7.605468 11.800782 17.730468 11.800782 28.496094 0 10.765624-4.191406 20.882812-11.800782 28.5l-64.777343 64.769531 64.777343 64.777343c7.609376 7.613282 11.804688 17.734376 11.804688 28.5 0 10.765626-4.195312 20.882813-11.804688 28.496094-7.613281 7.613282-17.734374 11.808594-28.5 11.808594zm0-242.15625c-4.089843 0-7.929687 1.59375-10.820312 4.484375l-93.277344 93.269531c-2.890625 2.890625-4.484375 6.734375-4.484375 10.820313 0 4.09375 1.59375 7.9375 4.484375 10.820312l93.277344 93.277344c2.890625 2.890625 6.726562 4.484375 10.820312 4.484375 4.085938 0 7.929688-1.59375 10.820313-4.484375s4.480469-6.734375 4.480469-10.820313c0-4.085937-1.589844-7.929687-4.480469-10.820312l-73.621094-73.613281c-2.34375-2.347657-3.65625-5.527344-3.65625-8.84375 0-3.308594 1.3125-6.488281 3.65625-8.835938l73.621094-73.613281c2.890625-2.890625 4.480469-6.734375 4.480469-10.820312 0-4.085938-1.589844-7.929688-4.480469-10.820313s-6.734375-4.484375-10.820313-4.484375zm0 0" />
                      <path d="m443.210938 488.03125c-10.765626 0-20.886719-4.195312-28.492188-11.808594-7.613281-7.613281-11.808594-17.730468-11.808594-28.496094 0-10.765624 4.195313-20.886718 11.808594-28.5l64.773438-64.777343-64.773438-64.769531c-7.613281-7.613282-11.808594-17.738282-11.808594-28.5 0-10.765626 4.195313-20.890626 11.808594-28.5 7.609375-7.613282 17.734375-11.804688 28.492188-11.804688 10.769531 0 20.890624 4.191406 28.503906 11.804688l93.269531 93.273437c7.613281 7.613281 11.808594 17.734375 11.808594 28.496094 0 10.769531-4.195313 20.890625-11.808594 28.503906l-93.269531 93.269531c-7.613282 7.613282-17.734375 11.808594-28.503906 11.808594zm0-242.15625c-4.085938 0-7.929688 1.59375-10.820313 4.484375s-4.480469 6.734375-4.480469 10.820313c0 4.085937 1.589844 7.929687 4.480469 10.820312l73.621094 73.613281c2.34375 2.347657 3.65625 5.527344 3.65625 8.835938 0 3.316406-1.3125 6.496093-3.65625 8.84375l-73.621094 73.613281c-2.890625 2.890625-4.480469 6.734375-4.480469 10.820312 0 4.085938 1.589844 7.929688 4.480469 10.820313s6.734375 4.484375 10.820313 4.484375c4.09375 0 7.929687-1.59375 10.820312-4.484375l93.277344-93.277344c2.890625-2.882812 4.484375-6.726562 4.484375-10.820312 0-4.085938-1.59375-7.929688-4.484375-10.820313l-93.269532-93.269531c-2.898437-2.890625-6.738281-4.484375-10.828124-4.484375zm0 0" />
                      <path d="m302.554688 482.777344c-3.007813 0-6.027344-.34375-8.976563-1.015625-21.667969-4.9375-35.28125-26.582031-30.34375-48.25l40.105469-176.023438c4.933594-21.671875 26.578125-35.285156 48.246094-30.347656 10.492187 2.394531 19.433593 8.730469 25.167968 17.84375 5.726563 9.109375 7.566406 19.910156 5.175782 30.402344l-40.101563 176.03125c-4.210937 18.460937-20.359375 31.359375-39.273437 31.359375zm40.078124-231.640625c-6.984374 0-13.304687 4.816406-14.917968 11.902343l-40.105469 176.027344c-1.871094 8.226563 3.292969 16.445313 11.523437 18.320313 1.132813.257812 2.28125.390625 3.421876.390625 7.058593 0 13.324218-5.011719 14.898437-11.914063l40.101563-176.027343c.90625-3.984376.210937-8.085938-1.964844-11.542969-2.175782-3.460938-5.570313-5.863281-9.554688-6.773438-1.144531-.261719-2.28125-.382812-3.402344-.382812zm0 0" />
                    </svg>
                  </span>
                </div>
                <h3>
                  <Link to="/serviceMarketingIndex"  className="title-text-purple">BUSINESS DEVELOPMENT</Link>
                </h3>
                <p className="text-black">
                Boost efficiency, reach, and financial performance with remote teams, task automation, and revenue leak resolution. </p>
                <p>
                  <br/>
                </p>
              </div>
            </Link>
            <Link to="/serviceinfluenceIndex" className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div
                className="landing-page-card single-services-wrape bg-white"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="services-style-shape" data-parallax='{"y": 50}'>
                  <svg
                    id="svg6332"
                    height="512"
                    viewBox="0 0 32 32"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="layer3">
                      <path
                        id="path22222"
                        d="m6.0001 9.99995a1.9999999 1.9999999 0 0 1 -2.0001 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2.0001 1.99997z"
                      />
                      <path
                        id="path1977"
                        d="m18.0001 9.99995a1.9999999 1.9999999 0 0 1 -2 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2 1.99997z"
                      />
                      <path
                        id="path1859"
                        d="m30.0001 9.99995a1.9999999 1.9999999 0 0 1 -2 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2 1.99997z"
                      />
                      <path
                        id="path1861"
                        d="m30.0001 21.99997a1.9999999 1.9999999 0 0 1 -2 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2 1.99997z"
                      />
                      <path
                        id="path1863"
                        d="m17.6465 21.99997a1.9999999 1.9999999 0 0 1 -2 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2 1.99997z"
                      />
                      <path
                        id="path1865"
                        d="m6.0001 21.99997a1.9999999 1.9999999 0 0 1 -2.0001 2.00005 1.9999999 1.9999999 0 0 1 -2-2.00005 1.9999999 1.9999999 0 0 1 2-1.99997 1.9999999 1.9999999 0 0 1 2.0001 1.99997z"
                      />
                    </g>
                  </svg>
                </div>
                <div className="single-services-icon">
                  <span>
                    <svg
                      height="512"
                      viewBox="0 0 64 64"
                      width="512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m37 54h-2v-9.8a1 1 0 0 0 -2 0v9.8h-26v-20h8.32a1 1 0 0 0 0-2h-9.32a1 1 0 0 0 -1 1v21h-2a1 1 0 0 0 -1 1v2a5 5 0 0 0 5 5h26a5 5 0 0 0 5-5v-2a1 1 0 0 0 -1-1zm-1 3a3 3 0 0 1 -3 3h-2v-1a1 1 0 0 0 -2 0v1h-18v-1a1 1 0 0 0 -2 0v1h-2a3 3 0 0 1 -3-3v-1h32z" />
                      <path d="m54 8h2a1 1 0 0 0 0-2h-2a3 3 0 0 0 -3 3v1h-1a1 1 0 0 0 0 2h1v3a1 1 0 0 0 2 0v-3h1a1 1 0 0 0 0-2h-1v-1a1 1 0 0 1 1-1z" />
                      <path d="m59 2h-12a3 3 0 0 0 -3 3v2a1 1 0 0 0 2 0v-2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1-1v-6a1 1 0 0 0 -2 0v6a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-12a3 3 0 0 0 -3-3z" />
                      <path d="m49 42a4 4 0 1 0 4-4 4 4 0 0 0 -4 4zm6 0a2 2 0 1 1 -2-2 2 2 0 0 1 2 2z" />
                      <path d="m59 33h-12a3 3 0 0 0 -3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-2a1 1 0 0 0 -2 0v2a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1-1v-12a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6a1 1 0 0 0 2 0v-6a3 3 0 0 0 -3-3z" />
                      <path d="m24.47 13.85a1 1 0 0 0 .53.15 1 1 0 0 0 .45-.11l6-3a1 1 0 0 0 0-1.78l-6-3a1 1 0 0 0 -1.45.89v6a1 1 0 0 0 .47.85zm1.53-5.23 2.76 1.38-2.76 1.38z" />
                      <path d="m27 19a9 9 0 1 0 -9-9 9 9 0 0 0 9 9zm0-16a7 7 0 1 1 -7 7 7 7 0 0 1 7-7z" />
                      <path d="m37 23a1 1 0 0 0 .8-.4l3-4a1 1 0 0 0 -1.6-1.2l-3 4a1 1 0 0 0 .2 1.4 1 1 0 0 0 .6.2z" />
                      <path d="m39.2 26.6a1 1 0 0 0 .8.4 1 1 0 0 0 .6-.2l4-3a1 1 0 1 0 -1.2-1.6l-4 3a1 1 0 0 0 -.2 1.4z" />
                      <path d="m49 28.8a1 1 0 0 0 -1.2-.8l-5 1a1 1 0 0 0 .2 2h.2l5-1a1 1 0 0 0 .8-1.2z" />
                      <path d="m3 29h12a1 1 0 0 0 1-1v-16a1 1 0 0 0 -1-1h-12a1 1 0 0 0 -1 1v16a1 1 0 0 0 1 1zm1-16h10v14h-10z" />
                      <path d="m11 15h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z" />
                      <path d="m11 19h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2z" />
                      <path d="m11 23h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2z" />
                      <path d="m36.12 28.7a1 1 0 0 0 -1.74 1l4.41 7.63-16 6s0 0-.07 0h-.08l-2.6 1.5-1.73 1a2 2 0 0 1 -1.52.2 2 2 0 0 1 -1.21-.93l-2-3.46a2 2 0 0 1 .75-2.64l3.46-2 2.5 4.33a1 1 0 0 0 .87.5 1 1 0 0 0 .87-1.5l-2.58-4.46 12.55-10.35.41.71a1 1 0 1 0 1.74-1l-1-1.73a1 1 0 0 0 -.69-.48 1 1 0 0 0 -.82.21l-14 11.54-4.26 2.46a4 4 0 0 0 -1.84 4.53l-1 .57a3 3 0 0 0 .72 5.5 3 3 0 0 0 .78.1 3.06 3.06 0 0 0 1.5-.4l1-.57a3.88 3.88 0 0 0 1.75 1.04 3.79 3.79 0 0 0 1.05.14 3.94 3.94 0 0 0 2-.54l.87-.5 2 3.47a3 3 0 0 0 4.09 1.1 3 3 0 0 0 1.1-4.1l-1.82-3.17 15-5.63a1 1 0 0 0 .59-.6 1 1 0 0 0 -.07-.83zm-23.62 17.09a1 1 0 0 1 -.76.1 1 1 0 0 1 -.61-.46 1 1 0 0 1 -.1-.76 1 1 0 0 1 .47-.61l.87-.5 1 1.73zm13.16 2.8a1 1 0 0 1 .1.76 1 1 0 0 1 -.47.6 1 1 0 0 1 -1.36-.36l-2-3.47 1.66-.95h.08z" />
                    </svg>
                  </span>
                </div>
                <h3>
                  <Link to="/serviceinfluenceIndex" className="title-text-purple">DIGITAL MARKETING</Link>
                </h3>
                <p className="text-black">
                Enhancing online presence through digital advertising, social media management, and lead generation to reach ideal customers.
                </p>
                <p style={{paddingBottom:15}}></p>
              </div>
            </Link>
          </div>
        </div>

        <div className="khalif-more-services text-center">
          {/* <Link to="#" className="btn-2 text-uppercase mt-70 bgc-1 btn-tilt">
            <span>
              <svg
                id="Layer_2"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 95.91"
              >
                <path
                  d="M259.88,468.09l-52,45.93-5.1-5.52,43.33-38.41H131.88v-8H246.09l-43.25-38.36,5-5.62,52.06,46Z"
                  transform="translate(-131.88 -418.11)"
                />
              </svg>
            </span>
            more services
          </Link> */}
        </div>
      </div>
    </>
  );
}

export default ServicesSection;
