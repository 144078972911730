import React, { useEffect } from "react";
import MarketingHeroo from "./marketingHeroo";
import MarketingService from "./marketingService";
// import MarketingAcordion from "./marketingAcordian";
// import BrandsComponent from "../../../components/brands";


function MarketingIndex() {
  useEffect(()=>{
    document.title = "AFFINITY Marketing"
  },[])
  return (
    <>
    <MarketingHeroo />
    <MarketingService />
    {/* <MarketingAcordion /> */}
    {/* <BrandsComponent /> */}
    </>
  );
}

export default MarketingIndex;
