import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ColorSwitcher = () => {
  const [activeStyle, setActiveStyle] = useState('color1');

  // Function to change the active style
  const setActiveStyleSheet = (style) => {
    setActiveStyle(style);
  };

  return (
    <div id="switcher" className="">
      <div className="content-switcher">
        <h4>COLOR SWITCHER</h4>
        <ul>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((color) => (
            <li key={`color${color}`}>
              <Link
                to="#"
                onClick={() => setActiveStyleSheet(`color${color}`)}
                title={`color${color}`}
                className={`color sbgc-${color} ${activeStyle === `color${color}` ? 'active' : ''}`}
              ></Link>
            </li>
          ))}
        </ul>
        <div id="hideSwitcher">&times;</div>
      </div>
    </div>
  );
};

export default ColorSwitcher;
