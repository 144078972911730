import React, { useEffect } from "react";
import BlogsHero from "./blogsHero";
import BlogsArea from "./blogsArea";
// import BrandsComponent from "../../components/brands";
// import VideoComponent from "../../components/video";

function BlogsIndex() {
  useEffect(()=>{
    document.title = "AFFINITY Blogs"
  },[])
  return (
    <>
    <BlogsHero />
    <BlogsArea />
    {/* <VideoComponent/> */}
    {/* <BrandsComponent /> */}
    </>
  );
}

export default BlogsIndex;
