import React from 'react';
import { Link } from 'react-router-dom';

const AboutHero = () => {
    return (
        <div id="khalif-breadcrumb-wrape" className="khalif-breadcrumb-wrape breadcrumb-bg text-center d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="offset-xl-2 col-xl-8">
                        <div className="khalif-breadcrumb-content">
                            <h2>about us</h2>
                            <ul className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><Link to="#" title="Home">Home</Link></li>
                                <li className="breadcrumb-item active">about us</li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutHero;
