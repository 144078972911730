import { Link } from "react-router-dom";

function HeroSec() {

  const openCalendlyPopup = () => {
    window.Calendly.initPopupWidget({
      url: "https://api.leadconnectorhq.com/widget/booking/io8V5ySgkA4MqsFxLaeK",
    });
  };

  return (
    <>
      {/* <!-- khalif hero area start --> */}
      <div class="khalif-hero-wrape hero-bg avatar">
        <div class="hero-content">
          <div class="container">
            <div class="row align-items-center">
              <div class="offset-xl-2 col-xl-10 offset-lg-2 col-lg-10 col-md-12">
                <ul class="khalif-hero-social">
                  <li>
                    <a href="https://www.facebook.com/people/Affinity-Digital/61555993337569">facebook</a>
                  </li>
                  <li>
                    <a href="https://twitter.com/KumailReza83949">twitter</a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/affinitydigitalagency/">instagram</a>
                  </li>
                  <li>
                    <a href="https://linkedin.com/company/affinity-digital-marketing-agency">linkedin</a>
                  </li>
                </ul>
                <h2 class="style-title">affinity</h2>
                <h1 data-parallax='{"y": -60}'>
                  Emerging <br /> Tech <br /> Support
                </h1>
                <div class="hero-btn-para-wrape">
                  <div class="hero-btn position-relative">
                    
                    <Link
                      onClick={openCalendlyPopup}
                      class="btn-2 text-center text-uppercase bgc-3 btn-tilt f-25"
                    >
                      <span>
                        <svg
                          id="Layer_9"
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 128 95.91"
                        >
                          <path
                            d="M259.88,468.09l-52,45.93-5.1-5.52,43.33-38.41H131.88v-8H246.09l-43.25-38.36,5-5.62,52.06,46Z"
                            transform="translate(-131.88 -418.11)"
                          ></path>
                        </svg>
                      </span>
                      Book an appointment
                    </Link>
                  </div>
                  <div class="hero-para">
                    <p>
					  We are startup enthusiasts, committed to
                      launching your product with confidence and delivering it
                      to your target audience.Our passion
                      revolves around the world of startups, and we are
                      dedicated to turning your entrepreneurial dreams into
                      reality.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- khalif hero area end --> */}
    </>
  );
}

export default HeroSec;
