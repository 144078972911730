import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";

// Define an array of blog posts
const blogPosts = [
  {
    title: "Marketing Strategies",
    image: "assets/img/blog/dlc.jpg",
  },
  {
    title: "Leverage agile frameworks to provide a robust",
    image: "assets/img/blog/m3.webp",
  },
  {
    title: "Development Knowledge",
    image: "assets/img/blog/dd.webp",
  },
  {
    title: "Unlocking the Entrepreneurial Mindset",
    image: "assets/img/blog/dd1.avif",
  },
];

function BlogCarousel() {
  const options = {
    autoplay: true,
    // nav: true,
    // navText: ["←", "→"],
    loop: true,
    margin: 0,
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
    autoplayTimeout: 2000,
  };

  return (
    <>
    <div className="khalif-blog-wrape pb-100 avatar">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title-wrape text-uppercase text-center position-relative mb-80">
              <h3>blog</h3>
              <h4>latest news</h4>
            </div>
            <OwlCarousel className="khalif-all-blog-list owl-carousel owl-theme" {...options}>
              {blogPosts.map((post, index) => (
                <div key={index} className="khalif-single-blog-wrape mb-30 magic-hover magic-hover__square">
                  <img src={post.image} alt="blog" />
                  <div className="single-blog-content">
                    <h4>
                      <Link to="#" className="post-title">
                        {post.title}
                      </Link>
                    </h4>
                    {/* <ul className="single-post-info">
                      <li className="blog-date">{post.date}</li>
                      <li className="blog-admin">
                        <Link to="#">by {post.admin}</Link>
                      </li>
                    </ul> */}
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
    <style>
      {
        `
        @media only screen and (max-width: 600px) {
          .post-title {
            font-size: 10px;
            font-weight: 300;
            line-height: 20px;
        }
        }
        `
      }
    </style>
    </>
  );
}

export default BlogCarousel;
