import React from "react";
import "owl.carousel";
import OwlCarousel from "react-owl-carousel";

// Define an array of testimonial objects
const testimonials = [
  {
    name: " Emily Trudeau",
    text: "Affinity's services have been an absolute game-changer for our business! Their AI-Integration has streamlined our sales process beyond expectation. The integration of AI technology has not only saved us time but has also boosted our sales performance significantly. We're thrilled with the results!",
    svgPath: "M100 100 ...",
  },
  {
    name: "Ava Thompson",
    text: "Partnering with Affinity for our Business Development needs has been an absolute pleasure. Their team's dedication to understanding our business intricacies and market dynamics has been commendable. Their tailored strategies have not only met but exceeded our expectations, resulting in unprecedented growth and success. We're truly grateful for their collaborative approach and expertise.  ",
    svgPath: "M150 150 ...",
  },
  {
    name: "Ottawa",
    text: "Choosing Affinity for our Digital Marketing needs was one of the best decisions we've made. Their strategies are fresh, innovative, and incredibly effective. From revamping our social media presence to optimizing our website, they've left no stone unturned. Our online visibility has skyrocketed, and we're attracting more clients than ever. Thanks, Affinity, for your outstanding work! ",
    svgPath: "M150 150 ...",
  },
];

function TestimonialCarousel() {
  const options = {
    autoplay: true,
    nav: true,
    navText: ["←", "→"],
    loop: true,
    margin: 0,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    autoplayTimeout: 5000,
  };

  return (
    <>
      <div className="khalif-testimonial-wrape text-center position-relative testimonial-bg pt-200 pb-100 avatar">
        <div className="container">
          <div className="row">
            <div className="offset-xl-2 col-xl-8"></div>
            <div className="section-title-wrape text-uppercase position-relative mb-80">
              <h3>Testimonials</h3>
              <h4>latest Reviews </h4>
            </div>
            <OwlCarousel
              className="all-testimonial owl-carousel owl-theme text-center"
              {...options}
            >
              {testimonials.map((testimonial, index) => (
                <div key={index} className="item">
                  <h2>{testimonial.text}</h2>
                  <h4 className="mt-3">{testimonial.name}</h4>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
      <style>
        {`
          /* Styling for the container with display, alignment, padding, and background color */
          .testimonial-container {
            display: flex;
            align-items: center;
            padding: 40px;
            background: #000;
          }
          
          /* Styling for images within the Owl Carousel */
          .owl-theme img {
            width: 100%;
            min-height: 200px;
          }
          
          /* Styling for previous and next navigation buttons */
          .owl-carousel .owl-prev,
          .owl-carousel .owl-next {
            position: absolute;
            top: 40%;
            transform: translateY(-50%);
            background-color: #fff !important;
            color: #000 !important;
            font-size: 1.3em !important;
            width: 40px;
            height: 40px;
            border-radius: 100% !important;
            font-weight: bold;
            padding: 15px !important;
            text-align: center;
            box-shadow: 0px 0px 10px 0px #000;
          }
          
          /* Styling for previous and next navigation buttons on hover */
          .owl-carousel .owl-prev:hover,
          .owl-carousel .owl-next:hover {
            box-shadow: 0px 0px 0px 0px #000;
            background-color: red !important;
            color: #fff !important;
          }
          
          /* Styling for the previous navigation button */
          .owl-carousel .owl-prev {
            left: -30px;
          }
          
          /* Styling for the next navigation button */
          .owl-carousel .owl-next {
            right: -30px;
          }
          
          /* Styling for the active dot in the pagination */
          .owl-theme .owl-dots .owl-dot.active span {
            background: #ada2ff !important;
            width: 25px;
            transition: 0.2s ease-in-out;
          }
          
          /* Styling for the hover effect on pagination dots */
          .owl-theme .owl-dots .owl-dot:hover span {
            background: #ada2ff !important;
            transition: 0.2s ease-in-out;
          }
          
            `}
      </style>
    </>
  );
}

export default TestimonialCarousel;
