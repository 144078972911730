import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

function MarketingService() {
  const [data, setData] = useState([]);

  const fetchDevelopmentData = async () => {
    try {
      const userResponse = await axios.get("https://affinityapi.dev-sh.xyz/api/marketing", {
        headers: {
          Accept: "application/json",
        },
      });

      console.log(userResponse, "Success");

      if (userResponse.data.status === true) {
        toast.success("Please Check your details");
        setData(userResponse?.data?.data); //
      } else {
        toast.error("Data not found");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchDevelopmentData();
  }, []);

  return (
    <>
    <div className="khalif-services-wrap pt-100 pb-100 avatar">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title-wrape text-center text-uppercase position-relative mb-100">
              <h3>Case Studies</h3>
              <h4>Marketing</h4>
            </div>
          </div>
          <div className="container">
            {data.map((item, index) => (
              <div className="row mt-5" key={index}>
                <div className="col-md-3 mt-5 p-3 mr-4" style={{ textAlign: "center" }}>
                  {item.thumbnail && (
                    <img src={item.thumbnail} alt={item.thumbnail} className="title" />
                  )}
                  {item.title && <h5>{item.title} </h5>}
                  {item.desc && <p>{item.desc} </p>}
                </div>

                {item.images.map((image, imageIndex) => (
                  <div className="col-md-3 p-4 text-center" key={imageIndex}>
                    <img src={image.value} alt={{imageIndex}} className="image" />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    <style>
      {
        `
        img.title {
          max-width: 100px;
      }
      p {
        font-family: 'Jost', sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: white;
    }
    img.image{
        max-height: 300px;
        min-height: 225px;
        width: 170px;
        border-radius: 15px;
    }
        `
      }
    </style>
    </>
  );
}

export default MarketingService;
