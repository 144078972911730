import React from "react";
// import { Link } from "react-router-dom";

const ShortAbout = () => {
  return (
    <>
    <div className="khalif-about-wrape mb-100 avatar bg-white mb-0 pb-50">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12">
            <div className="about-left-img-wrape">
              <div
                className="about-img1 position-relative"
                data-parallax='{"y": 50}'
              >
                <div
                  className="about-exprience mb-20"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                  data-aos-duration="2000"
                >
                  <h3 className="text-black">
                    <span>
                      <b className="counter text-black">Case </b>
                    </span>
                    Studies
                  </h3>
                </div>
                <img
                  data-aos="fade-right"
                  data-aos-duration="1500"
                  src="/assets/img/about/cc2.jpg"
                  alt="about-img"
                />
                <div className="about-style-title text-uppercase text-purple">affinity</div>
              </div>
              <div
                className="about-img2"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <img className="hdninmub" src="/assets/img/about/cs22.png" alt="about-img" />
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12">
            <div className="about-short-brief " data-parallax='{"y": 70}'>
              <h2 className="text-uppercase text-purple">
                 We efficiently manage our <b style={{color:"#000000"}}> agency </b> alongside our Dedicated <b style={{color:"#000000"}}>Team</b>{" "}
                 Members.
              </h2>
              <p className="text-black">
                Explore our portfolio to uncover a glimpse of our exceptional
                work. Witness a selection of apps we've masterfully crafted and
                effectively marketed, leveraging our in-house tools and
                expertise
              </p>
              {/* <Link
                to="#"
                className="btn-2 bgc-2 text-center text-uppercase btn-tilt"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 128 95.91"
                  >
                    <path
                      d="M259.88,468.09l-52,45.93-5.1-5.52,43.33-38.41H131.88v-8H246.09l-43.25-38.36,5-5.62,52.06,46Z"
                      transform="translate(-131.88 -418.11)"
                    />
                  </svg>
                </span>
                learn more
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    <style>
      {
        `
        @media only screen and (max-width: 600px) {
          img.hdninmub {
            display: none;
        }
        }
        `
      }
    </style>
    </>
  );
};

export default ShortAbout;
