import React, { useEffect } from 'react';

const AboutCounter = () => {
  useEffect(() => {
  }, []);

  return (
    <div className="khalif-counter-wrape counter-bg">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
            <div className="khalif-single-counter text-center" data-aos="fade-right" data-aos-delay="700">
              <div className="single-count-box">
                <h3><span className="counter">35</span>k</h3>
                <div className="counter-circle"></div>
              </div>
              <h4 className="count-name">project done</h4>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
            <div className="khalif-single-counter text-center" data-aos="fade-right" data-aos-delay="500">
              <div className="single-count-box">
                <h3><span className="counter">18</span>+</h3>
                <div className="counter-circle"></div>
              </div>
              <h4 className="count-name">experience</h4>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
            <div className="khalif-single-counter text-center" data-aos="fade-right" data-aos-delay="300">
              <div className="single-count-box">
                <h3><span className="counter">60</span>+</h3>
                <div className="counter-circle"></div>
              </div>
              <h4 className="count-name">team member</h4>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
            <div className="khalif-single-counter text-center" data-aos="fade-right" data-aos-delay="100">
              <div className="single-count-box">
                <h3><span className="counter">14</span></h3>
                <div className="counter-circle"></div>
              </div>
              <h4 className="count-name">awards</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCounter;
